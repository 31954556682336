<template>
  <div class="banner">
    <warning-tip code="SDYHYJCWW"></warning-tip>
    <main-top title="一键查旺旺" desc="查黑号是千万级黑号数据库，由大数据采集+用户打标共享，可以有效排除市面上大部分的降权黑号、刷号、骗子号" spend="SDYHYJCWW"
      liability="本网站的所有旺旺买家信息均来源于公开领域，使用者不得用于不正当竞争或者其他商业用途，若使用产生的一切法律后果均由使用者承担，与本网站无关。">
      <!-- 使用搜索框组件 -->
      <InputView :CanYouClick="CanYouClick" 
        placeholder-name="请输入旺旺名"
        @sendSearchName="getSearchName"
        @change="resultMsg = null"
          style="margin-bottom:0;">
          <template v-slot:result=""  v-if="resultMsg">
            <!-- <i class="el-icon-close res-close"
              @click="resultMsg = null"></i> -->
              <div>
                <div style="color:red;font-size:14px;">
                  <span v-html="resultMsg"></span>
                  <el-tooltip class="item" effect="dark" content="狂刷号倾向：通过黑星指数判断狂刷号危险级别，黑星越多表示危险系数越高，实际情况商家应结合其他指标共同参考"
                    placement="top">
                    <i class="el-icon-question" style="color:#999;font-size:16px;"></i>
                  </el-tooltip>
                </div>
                <div style="color:black;font-size:12px;">
                  <span v-if="tableData.weekCount < 2">近期较少商家查询</span>
                  <span v-if="tableData.weekCount >= 2 && tableData.weekCount < 10">近期已有商家查询，请注意</span>
                  <span v-if="tableData.weekCount > 10">近期较多商家查询，请特别主意！</span>
                </div>
              </div>
          </template>
        </InputView>
        <discount-tool model-code="SDYHYJCWW" ref="discountTool"
          style="padding-right:0;padding-bottom:0;"></discount-tool>

    </main-top>


    <auto-layout>
      <!-- 基本信息 -->
      <bordure-row title="基本信息" class="detail-body" shadow width="65%">
        <div class="tableList">
          <table class="table" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td class="tableLabel">买家</td>
                <td >{{ tableData.wangwang }}</td>
                <td class="tableLabel">性别</td>
                <td>{{ tableData.gender }}</td>
                <td class="tableLabel">淘龄</td>
                <td>{{ tableData.taoling }}年+</td>
              </tr>
              <tr>
                
                <td class="tableLabel">信誉级别</td>
                <td>{{ tableData.creditLevel }}</td>
                <td class="tableLabel">买家信誉</td>
                <td>
                  <!-- {{tableData.buyerGoodNum}} -->
                  <img src="../../assets/wwIcon/b_red_1.gif" v-if="tableData.buyerGoodNum == '1心'" />
                  <img src="../../assets/wwIcon/b_red_2.gif" v-else-if="tableData.buyerGoodNum == '2心'" />
                  <img src="../../assets/wwIcon/b_red_3.gif" v-else-if="tableData.buyerGoodNum == '3心'" />
                  <img src="../../assets/wwIcon/b_red_4.gif" v-else-if="tableData.buyerGoodNum == '4心'" />
                  <img src="../../assets/wwIcon/b_red_5.gif" v-else-if="tableData.buyerGoodNum == '5心'" />
                  <img src="../../assets/wwIcon/b_blue_1.gif" v-else-if="tableData.buyerGoodNum == '1黄钻'" />
                  <img src="../../assets/wwIcon/b_blue_2.gif" v-else-if="tableData.buyerGoodNum == '2黄钻'" />
                  <img src="../../assets/wwIcon/b_blue_3.gif" v-else-if="tableData.buyerGoodNum == '3黄钻'" />
                  <img src="../../assets/wwIcon/b_blue_4.gif" v-else-if="tableData.buyerGoodNum == '4黄钻'" />
                  <img src="../../assets/wwIcon/b_blue_5.gif" v-else-if="tableData.buyerGoodNum == '5黄钻'" />
                  <span v-else>{{ tableData.buyerGoodNum }}</span>
                </td>
                <td class="tableLabel">商家信誉</td>
                <td>
                  <!-- {{tableData.sellerTotalNum}} -->
                  <img src="../../assets/wwIcon/s_red_1.gif" v-if="tableData.sellerTotalNum == '1心'" />
                  <img src="../../assets/wwIcon/s_red_2.gif" v-else-if="tableData.sellerTotalNum == '2心'" />
                  <img src="../../assets/wwIcon/s_red_3.gif" v-else-if="tableData.sellerTotalNum == '3心'" />
                  <img src="../../assets/wwIcon/s_red_4.gif" v-else-if="tableData.sellerTotalNum == '4心'" />
                  <img src="../../assets/wwIcon/s_red_5.gif" v-else-if="tableData.sellerTotalNum == '5心'" />
                  <img src="../../assets/wwIcon/s_blue_1.gif" v-else-if="tableData.sellerTotalNum == '1蓝钻'" />
                  <img src="../../assets/wwIcon/s_blue_2.gif" v-else-if="tableData.sellerTotalNum == '2蓝钻'" />
                  <img src="../../assets/wwIcon/s_blue_3.gif" v-else-if="tableData.sellerTotalNum == '3蓝钻'" />
                  <img src="../../assets/wwIcon/s_blue_4.gif" v-else-if="tableData.sellerTotalNum == '4蓝钻'" />
                  <img src="../../assets/wwIcon/s_blue_5.gif" v-else-if="tableData.sellerTotalNum == '5蓝钻'" />
                  <img src="../../assets/wwIcon/s_cap_1.gif" v-else-if="tableData.sellerTotalNum == '1蓝冠'" />
                  <img src="../../assets/wwIcon/s_cap_2.gif" v-else-if="tableData.sellerTotalNum == '2蓝冠'" />
                  <img src="../../assets/wwIcon/s_cap_3.gif" v-else-if="tableData.sellerTotalNum == '3蓝冠'" />
                  <img src="../../assets/wwIcon/s_cap_4.gif" v-else-if="tableData.sellerTotalNum == '4蓝冠'" />
                  <img src="../../assets/wwIcon/s_cap_5.gif" v-else-if="tableData.sellerTotalNum == '5蓝冠'" />
                  <img src="../../assets/wwIcon/s_crown_1.gif" v-else-if="tableData.sellerTotalNum == '1皇冠'" />
                  <img src="../../assets/wwIcon/s_crown_2.gif" v-else-if="tableData.sellerTotalNum == '2皇冠'" />
                  <img src="../../assets/wwIcon/s_crown_3.gif" v-else-if="tableData.sellerTotalNum == '3皇冠'" />
                  <img src="../../assets/wwIcon/s_crown_4.gif" v-else-if="tableData.sellerTotalNum == '4皇冠'" />
                  <img src="../../assets/wwIcon/s_crown_5.gif" v-else-if="tableData.sellerTotalNum == '5皇冠'" />
                  <span v-else>{{ tableData.sellerTotalNum }}</span>
                </td>
                
              </tr>
              <tr>
                <td class="tableLabel">淘气值</td>
                <td>{{ tableData.taoqiValue }}</td>
                <td class="tableLabel">
                  买家周平均
                  <el-tooltip class="item" effect="dark" content="买家帐号注册起到现在总的周平均评价点数！" placement="top">
                    <i class="el-icon-question" style="color:#999;font-size:16px;"></i>
                  </el-tooltip>
                </td>
                <td>{{ tableData.weekOrder }}</td>
                
                <td class="tableLabel">
                  买家月平均
                  <el-tooltip class="item" effect="dark" content="买家帐号注册起到现在总的月平均评价点数！" placement="top">
                    <i class="el-icon-question" style="color:#999;font-size:16px;"></i>
                  </el-tooltip>
                </td>
                <td>{{ tableData.monthOrder }}</td>
              </tr>
              <tr>
                
                <td class="tableLabel">收到好评率</td>
                <td>{{ tableData.receivedRate }}%</td>
                <td class="tableLabel">给出好评率</td>
                <td>{{ tableData.sentRate }}%</td>
                <td class="tableLabel">中差评数量</td>
                <td>中差评({{ tableData.badTotal }})</td>
              </tr>
              <tr>
              
                <td class="tableLabel">注册日期</td>
                <td>{{ tableData.wwcreatedStr }}</td>
                <td class="tableLabel">实名认证</td>
                <td>
                  <div v-if="tableData.renZheng" style="color:red;">已实名认证</div>
                  <div v-else>未实名认证</div>
                </td>
                <td class="tableLabel">会员等级</td>
                <td>
                  <img src="../../assets/imgs/putong.png" v-if="tableData.vipLevel == '0'"
                    style="vertical-align: middle;" />
                  <img src="../../assets/imgs/chaojihuiyuan.png" v-if="tableData.vipLevel == '10'"
                    style="vertical-align: middle;" />
                </td>
              </tr>
              <tr>
                <td class="tableLabel">查询次数</td>
                <td colspan="5" style="color:red;">该用户近一周查询商家数： {{ tableData.weekCount }}，
                  近一个月查询商家数：{{ tableData.countBefore }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </bordure-row>

      <bordure-row title="用户被打标记录情况" class="detail-body" shadow width="30%">
        <div class="marking_box">
          <div class="marking_item">
            <span class="marking_num" :style="tableData.fox == 0 ? { color: 'gray' } : {}">{{ tableData.fox }}</span>
            <p class="marking_title">狐狸
              <el-tooltip class="item" effect="dark" content="跑单，威胁，骗子，打假，差评，淘客等！" placement="top">
                <i class="el-icon-question" style="color:#999;font-size:16px;"></i>
              </el-tooltip>
            </p>
          </div>
          <div class="marking_item">
            <span class="marking_num"
              :style="tableData.jiangNum == 0 ? { color: 'gray' } : {}">{{ tableData.jiangNum }}</span>
            <p class="marking_title">降权处置
              <el-tooltip class="item" effect="dark"
                content="被稽查系统判定虚假交易过导致商家单品降权的帐号，下面的数字为降权店铺数量，实际降权的店铺数是大于等于显示出来的数字的！" placement="top">
                <i class="el-icon-question" style="color:#999;font-size:16px;"></i>
              </el-tooltip>
            </p>
          </div>
          <div class="marking_item">
            <span class="marking_num"
              :style="tableData.yunBlack == 0 ? { color: 'gray' } : {}">{{ tableData.yunBlack }}</span>
            <p class="marking_title">云黑名单
              <el-tooltip class="item" effect="dark"
                content="显示数量是这个号代表被淘宝服务市场第三方应用（比如**店长，*牛，旺店**，爱**易等）拦截过的号，可能是差评，敲诈， 骗子类的一些恶人，云黑名单来自于第三方应用海量的黑号数据库！"
                placement="top">
                <i class="el-icon-question" style="color:#999;font-size:16px;"></i>
              </el-tooltip>
            </p>
          </div>

          
        </div>

        <div class="query-result" >
              <div class="result-btn">
                <i class="score-icon"></i>
                <span class="label" >综合评分：</span>
                <span :class="resultColor" >{{tableData.synthesizeScore || '-'  }}分</span>
              </div>
              <div class="result-btn">
                <i class="safe-icon"></i>
                <span   class="label" >账号等级：</span>
                <span :class="resultColor"  >{{tableData.safety}}</span>
                <el-tooltip class="item" effect="dark"
                  placement="top">
                  <i class="el-icon-question" style="color:#999;font-size:16px;"></i>
                  <template v-slot:content>
                    <div>安全：多个检测维度得出结果，该淘宝帐号安全性高,不是职业差评师和黑名单帐号,不存在恶意中差评或者对店铺宝贝降权的隐患，可以交易。</div>
                    <div>一般：淘宝帐号安全等级一般，需要在交易中具体查看帐号近段时间的评价记录和购买记录，确实是否职业差评师，防止被中差评或者导致店铺商品降权。</div>
                    <div>危险：这样的帐号可能被淘宝列入刷单的名单，与之交易的淘宝卖家，会大大增加被淘宝隐形降权甚至直接降权的危险。不排除也有可能是职业差评师，请谨慎交易！</div>
                    <div>备注：等级结果说明仅供参考，分析维度不多，无法保证百分百准确</div>
                  </template>
                </el-tooltip>
              </div>
              <div v-if="tableData && tableData.length > 0" style="color:black;font-size:14px;">
                <span v-if="tableData.weekCount < 2">近期较少商家查询</span>
                <span v-if="tableData.weekCount >= 2 && tableData.weekCount < 10">近期已有商家查询，请注意</span>
                <span v-if="tableData.weekCount > 10">近期较多商家查询，请特别主意！</span>
              </div>
        </div>
      </bordure-row>
      
    </auto-layout>

    <bordure-row title="近期购买记录" class="detail-body" shadow  v-if="purchaseRecords">
      <!-- 近期购买记录 -->
      <div class="tableList">
        <!-- 第一行 -->
        <table class="table" cellspacing="0" cellpadding="0" style="border: 0px">
          <tbody>
            <tr>
              <th class="table-th">
                时间范围
              </th>
              <td style="line-height: 40px; border-bottom: 0px" v-for="(item, index) in purchaseRecords" :key="index">
                {{ item.endDate + "至" + item.startDate }}
              </td>
            </tr>
            <!-- 第二行 -->
            <tr>
              <th class="table-th" rowspan="2">购买记录</th>
              <td style="line-height: 40px;" v-for="(item, index) in purchaseRecords" :key="index">
                购物确认收货数：{{ item.count }}
              </td>
            </tr>
            <!-- 第三行 -->
            <tr>
              <td style="line-height: 40px;" v-for="(item, index) in purchaseRecords" :key="index">
                日均收货量：{{ item.avgCount }}
              </td>
            </tr>
          </tbody>
        </table>

      </div>
    </bordure-row>

    <!-- 讲解文案区域 -->
    <Introduce :introduce="introduce"></Introduce>
  </div>
</template>
<script>
import moment from "moment";
import InputView from "../public/InputView.vue";
import Introduce from "../public/Introduce.vue";
import {checkWangwang} from "../../request/api"; //这里是引入请求
import DiscountTool from '../public/DiscountTool.vue';


export default {
  data() {
    return {
      CanYouClick: true, //是否允许再次搜索
      resultMsg: "",
      // 基本信息data
      tableData: {
        buyerGoodNum: "2黄钻",
        gender: "男",
        wangwang: "XX******",
        weekCount: 9,
        fox: 0,
        wwcreatedStr: "2011-08-13",
        weekOrder: 1.47,
        monthOrder: 0.5,
        badTotal: "1",
        isOk: true,
        receivedRate: "99.8",
        renZheng: false,
        vipLevel: "0",
        sentRate: "100",
        sellerTotalNum: "2心",
        jiangNum: 0,
        countBefore: 0,
        yunBlack: 0,
        taoling: 8,
        taoqiValue: "<100",
        creditLevel:">=0",
        safety:"一般",
        synthesizeScore:80,
      },

      //近期购买记录
      purchaseRecords: null,

      introduce: [{
        title: "如何选择一个优质的买家号？",
        content: [
          "  1、买家信誉3心以上，注册时间大于一年，周平均在3以下，必须要实名认证。购物记录一个月内不超过40个确认收货。",
          "  2、恶意差评、降权处置、云黑名单的号一律排除；相关类目有符合自己类目标签的号优先；查骗子号中，查询出来的实名末位拼音必须和微信转账的实名一致。",
        ],
      },
      {
        title: "淘客黑号都分成哪几种？",
        content: [
          "  1、一种是刷单虚假交易降权导致的号，一种是恶意退款、恶意差评、职业打假导致的号。这些账号都会进入阿里阿里蚁盾的异常账号云标签监控。",
          "  2、刷单造成的淘宝黑号已经被阿里蚁盾系统隔离！这样黑号的危害大家都清楚了，应引起重视，所以补单就要查看淘宝号是不是淘宝黑号，信誉等级，待收货，评价，实名这些都要看一遍，补单遇到过多黑号操作，轻则无流量，甚至降权，重的直接扣分永久封店铺！",
        ],
      },
      {
        title: "针对恶意退款、恶意差评、职业打假导致的黑号？",
        content: [
          "  1、那些职业骗子的黑号，恶意给卖家差评，甚至职业打假利用违禁词广告法给卖家带来伤害的黑号，统统都在这里标记出来。",
        ],
      },
      ],
    };
  },
  computed:{
    resultColor(){
      if(this.tableData){
        let safety = this.tableData.safety;
        if(safety == "安全"){
          return "result-green";
        }else if(safety == "危险"){
          return "result-red";
        }else{
          return "result-normal";
        }
      }

      return "";
    }
  },
  components: {
    InputView,
    Introduce,
    DiscountTool,
  },
  mounted() {
    if (this.$store.state.token != null) {
      //初始化使用次数
      this.$nextTick(() => {
          this.$refs.discountTool.$emit("refresh");
      });
    }
  },
  methods: {

    //   子传父的方法
    getSearchName(value) {

      //清除为默认值
      for (let key in this.tableData) {
        this.tableData[key] = "";
      }

      if (this.purchaseRecords != null) {
        for (let index in this.purchaseRecords) {
          let item = this.purchaseRecords[index];
          if (item != null) {
            for (let key in item) {
              item[key] = "";
            }
          }
        }
      }


      this.$store.commit("alterLoadContent", "正在获取旺旺的相关数据");
      this.CanYouClick = false; //不允许再点击搜索
      checkWangwang({
        account: value
      }).then((data) => {
        let resultData = data.data.jqInfo || data.data;
        this.resultMsg = data.resultMsg;
        this.tableData = resultData;
        // this.jqInfo = data.data.jq.jqInfo;
        // this.xyInfo = data.data.xyData.xyInfo;
        // console.log(this.jq.jqInfo);


        this.purchaseRecords = resultData.purchaseRecords;

        //近期购买记录
        if (this.purchaseRecords != null) {
          this.purchaseRecords.forEach(function (item) {
            let endDate = moment(item.endDate);
            let startDate = moment(item.startDate);
            let days = endDate.diff(startDate, "days");
            if (days > 0) {
              item.avgCount = Math.round(item.count / days * 100) / 100;
            } else {
              item.avgCount = 0;
            }

          });

          if (this.purchaseRecords.length > 5) {
            this.purchaseRecords = this.purchaseRecords.slice(0, 5)
          }

        }


        //更新使用次数
        this.$refs.discountTool.$emit("refresh");

      }).finally(() => {
        this.$store.commit("alterJumpFlag", false);
        this.CanYouClick = true; //允许再次搜索
      });
    },
  },
};
</script>

<style lang="less" scoped>

.tableList {
  text-align: center;
  margin-bottom: 20px;
  overflow: auto;

  .table {
    width: 100%;
    border: 1px solid #ddd;
    border-collapse: collapse;
  }


  .table-th {
    width: 140px;
    border-right: 0px;
    border-bottom: 0px;
  }


  .table th,
  .table td {
    border: 1px solid #e5e5e5;
    border-collapse: collapse;
    height: 39px;
    font-size: 12px;
  }

  .table th,
  .table td {
    text-align: center;
    color: #333;
    height: 39px;
    padding: 0 8px;
    white-space: nowrap;
  }

  .table th {
    color: #666666;
    background-color: #f8f8f8;
    font-weight: normal;
  }

  .table td {
    color: #111111;
  }

  .tableLabel{
    background:#fafafa;
    width:120px;
  }

}

.marking_box {
  display: flex;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  padding: 50px 0;

  .marking_item {
    .marking_num {
      color: #FF0000;
      font-weight: 600;
      font-size: 30px;
    }

    .marking_title {
      font-size: 14px;
      margin-top: 20px;
    }
  }
}

.detail-body {
  margin-bottom: 30px;
}

.query-result {
  line-height: 2;
  padding: 10px;
  font-size: 16px;
}

.result-line{
  display: flex;
  padding:6px 20px;
  .label{
    text-align: right;
    margin-left: 31px;
  }
}

.result-btn{
  display: flex;
  // background-image:linear-gradient(20deg, #fff, #eee);
  background-color: #e9f2ff;
  border:solid 1px #ececec;
  border-bottom-color: #c1c1c1;
  border-radius: 6px;
  margin-top: 10px;
  padding:6px 20px;
  color: #007cdd;
  .label{
    margin-left:6px;
  }

}

.result-green{
  color:green;
}

.result-red{
  color:red;
}

.result-normal{
  color: #007cdd;
}

.score-icon{
  display: inline-block;
  background-image: url('../../assets/imgs/score-icon.png');
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}

.safe-icon{
  display: inline-block;
  background-image: url('../../assets/imgs/safe-icon.png');
  background-size: auto 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
}

.award_box {
  width: 30%;
  margin-bottom: 30px;
  padding: 20px;
  background: linear-gradient(180deg, #dabfff, #409eff);
  border-radius: 8px;

  .award_title {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;
  }
}

.award_list {
  p {
    line-height: 1.5;
    font-size: 16px;
    color: #333;
    margin-bottom: 5px;
  }
}

.notice_list {
  margin-top: 20px;

  p {
    line-height: 1.4;
    font-size: 14px;
    color: #000;
  }
}

.welfare_share {
  display: flex;
  align-items: center;
}

.res-close{
  position: absolute;
  top: 2px;
  right: 6px;
  cursor: pointer;
  color: #000;
}

.res-close:active{
  background-color: #eee;
  color: red;
}

@media screen and (max-width: 640px) {
  .detail-body {
    width: 100% !important;
  }

}

</style>